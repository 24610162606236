<template>
  <div>
    <!-- Header Alert -->
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">Daftar <b>Tempat Usaha</b></div>
      <b-button
        squared
        variant="primary"
        @click="$router.push('/village-business/add')"
        v-if="btnAccess"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-between mt-n3">
              <div class="col-md-4">
                <treeselect
                  v-model="filter.category_id"
                  :multiple="false"
                  placeholder="Pilih Bidang Usaha"
                  :options="report_category"
                  @input="filterByCategory"
                />
              </div>
              
              <div class="col-md-4">
                <b-input-group>
                  <b-form-input
                    type="text"
                    placeholder="Masukkan Nama Usaha"
                    v-model="filterbyName"
                  ></b-form-input>
                  <template #append>
                    <b-button squared variant="danger" @click="resetFilter"
                      >Reset</b-button
                    >
                  </template>
                </b-input-group>
              </div>
            </div>
            <!-- <div class="row justify-content-start">
              <div class="col py-0">
                <b-button variant="danger" size="sm">
                  <i class="fas fa-file-pdf text-white px-0"></i>
                </b-button>
                <b-button
                  size="sm"
                  class="ml-1"
                  style="background-color: green"
                >
                  <i class="fas fa-file-excel text-white px-0"></i>
                </b-button>
              </div>
            </div> -->

            <!-- time series filter -->

            <b-table
              striped
              hover
              :items="items"
              :fields="fields"
              class="mt-3"
              responsive
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.key === 'action' ? '25%' : '' }"
                />
              </template>

              <!-- <template #cell(status_name)="data">
                <b-badge
                  pill
                  :variant="data.item.variant"
                  style="cursor: pointer"
                  @click="statusOnClick(data.item.status, data.item.id)"
                  >{{ data.item.status_name }}</b-badge
                >
              </template> -->

              <template #cell(action)="data">
                <b-button
                  size="sm"
                  class="mr-1 btn-info"
                  v-b-tooltip.hover
                  title="Detail"
                  @click="$router.push(`/village-business/detail/${data.item.id}`)"
                  ><i class="fas fa-eye px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="mr-1 btn-success"
                  v-b-tooltip.hover
                  title="Edit"
                  v-if="btnAccess"
                  @click="$router.push(`/village-business/edit/${data.item.id}`)"
                  ><i class="fas fa-edit px-0"></i
                ></b-button>
                <b-button
                  size="sm"
                  class="btn-danger"
                  v-b-tooltip.hover
                  title="Hapus"
                  v-if="btnAccess"
                  @click="deleteData(data.item.id)"
                  ><i class="fas fa-trash px-0"></i
                ></b-button>
              </template>
            </b-table>
            <b-pagination
              class="mt-4"
              v-model="currentPage"
              :total-rows="totalRows"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import moment from "moment";

export default {
 
  data() {
    return {
      // filter
      filter: {
        name: "",
        category_id: "",
      },
      // Pagination
      perPage: 5,
      currentPage: 1,
      totalRows: 60,
      fields: [
        {
          key: "number",
          label: "No",
          sortable: true,
        },
        {
          key: "business_name",
          label: "Nama Usaha",
          sortable: true,
        },
        {
          key: "display_address_map",
          label: "Alamat Peta",
          sortable: true,
        },
        // {
        //   key: "village_name",
        //   label: "Kelurahan/Desa",
        //   sortable: true,
        // },
        {
          key: "display_commodity_name",
          label: "Komiditas",
        },
        {
          key: "village_business_category_name",
          label: "Bidang Usaha",
        },
        {
          key: "action",
          label: "Aksi",
          tdClass: "nowrap",
        },
      ],
      items: [],
      report_category: {},
      detail: {},
      onShowPeriod: false,
      btnActive: {
        days: false,
        month: true,
        lastMonth: false,
        year: false,
        period: false,
      },
      debounceName: "",
      timeout: null,
      // status
      newStatus: "",
      dataStatusId: "",
      statusOption: [
        { label: "Diajukan", id: 1 },
        { label: "Diproses", id: 2 },
        { label: "Selesai", id: 3 },
        { label: "Ditolak", id: 4 },
      ],
      formNewStatus: {
        status: "",
        _method: "put",
      },
      // access
      btnAccess: true,
    };
  },
  methods: {
    showModal(data) {
      this.$bvModal.show("detail-village-business");
      this.detail = data;
    },

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      this.pagination();
    },

    async pagination() {
      let filterParams = `&category_id=${this.filter.category_id}&name=${this.filter.name}`;
      let response = await module.paginate(
        "village-business",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;

      console.log(this.items);
    },

    filterByCategory(evt) {
      if (typeof evt == "undefined") {
        this.filter.category_id = "";
      }
      this.pagination();
    },

    statusOnClick(status, id) {
      this.newStatus = status;
      this.dataStatusId = id;
      this.$bvModal.show("modal-status");
    },

    async getCategoryOption() {
      let response = await module.setTreeSelect("village-business-category");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.report_category = response.data;
        this.report_category.unshift({
          label: "Pilih Bidang Usaha",
          id: "",
          isDisabled: true,
        });
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("village-business/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    resetFilter() {
      this.filter.category_id = "";
      this.filter.name = "";

      this.pagination();
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("village-business/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btnAccess = true;
        }
      }
    },
  },
  computed: {
    filterbyName: {
      get() {
        return this.filter.name;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.filter.name = val;
          this.pagination();
        }, 500);
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Daftar Tempat Usaha" },
    ]);

    this.pagination();
    this.getCategoryOption();
  },
};
</script>

<style>
.nowrap {
  white-space: nowrap;
}
</style>
